/* COLORS
========================================================================== */

$primary: #029154;
$secondary: #02b569;
$warning: #f2c94c;
$danger: #ff322e;
$black: #000000;
$white: #ffffff;
$grey: #989e9b;
$dark_grey: #6f7573;
$dark: #1f2120;
$dark_light: #3e4241;

/* TYPOFRAPHY
========================================================================== */

$font_system: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Inter', 'Oxygen', 'Ubuntu', 'Cantarell',
  'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$font_montserrat: 'Montserrat', sans-serif;
$font_title: 24px;
$font_title_2: 22px;
$font_title_3: 20px;
$font_headline: 18px;
$font_body_2: 16px;
$font_body: 14px;
$font_number: 13px;
$font_small: 12px;
$font_icon: 20px;
